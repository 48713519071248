import { useState, useRef, useEffect } from 'react'
import { alertMessage } from 'common/utils'
import CustomDialog from '../../../wrapper/CustomDialog'
import { DialogActions, DialogContent, TextField, Select, Input, MenuItem } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import { Controller, useForm } from 'react-hook-form'
import moment from 'moment'
import imageCompression from 'browser-image-compression'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import {
	adsImageUpload,
	getCampaignList,
	getManagerList,
	getCostList,
	updateSubject,
	registSubject,
	deleteSubject
} from 'api/ads'
import { debounce } from 'utils'

const DetailDialog = ({ dialogType, showDetail, setShowDetail, detailData }) => {
	const [fileName, setFileName] = useState('')
	const [adLocation, setAdLocation] = useState('')
	const [adType, setAdType] = useState('')
	const [contents, setContents] = useState('')
	const [showText, setShowText] = useState(false)
	const [managerInfo, setManagerInfo] = useState([])
	const [showManagerList, setShowManagerList] = useState(false)
	const [campaignInfo, setCampaignInfo] = useState([])
	const [costInfo, setCostInfo] = useState([])
	const [detailCost, setDetailCost] = useState({})

	const inputRef = useRef(null)

	const { control, handleSubmit, watch } = useForm({
		defaultValues: { ...detailData }
	})

	const watchImageUrl = watch('imageUrl')

	useEffect(() => {
		if (Object.keys(detailData).length > 0) {
			loadCampaignInfo(detailData.manager?.seq)
			loadCostInfo(detailData.campaign?.seq)

			setAdLocation(detailData.inventory)
			setAdType(detailData.type)
			setContents(detailData.contents)

			setDetailCost(detailData.cost)

			if(detailData.type === 'notice') setShowText(true)
			else if(detailData.type === 'feed') setShowText(true)
		}
	}, [])

	const handleSend = async (data) => {
		{
			const params = {
				title: data.title,
				contents: contents,
				imageUrl: data.imageUrl,
				targetUrl: data.targetUrl,
				type: adType, // feed popup banner notice
				managerSeq: data.manager.seq || managerInfo.seq,
				campaignSeq: data.campaign.seq,
				startDate: moment(data.startDate).add(9,'h').toISOString(),
				endDate: moment(data.endDate).add(9,'h').toISOString(),
				alt: data.alt,
				inventory: adLocation, // place moim myPlace myPage
				isPublished: true,
				costSeq: data.cost.seq
			}
			try {
				const res = detailData?.seq ? await updateSubject(detailData?.seq || '', params) : await registSubject(params)
				if (res?.status?.code === 200 || res?.status?.code === 201) {
					alertMessage({ title: '알림', type: 'success', message: '소재가 등록되었습니다.' })
					setShowDetail(false)
					setTimeout(() => {
						window.location.reload()
					}, 100)
				} else {
					alertMessage({
						title: '경고',
						type: 'danger',
						message: res?.response?.data?.message || '에러가 발생하였습니다.\n관리자에게 문의해주세요.'
					})
					setShowDetail(false)
				}
			} catch (error) {
				console.error(error)
				alertMessage({ title: '경고', type: 'danger', message: '에러가 발생하였습니다.\n관리자에게 문의해주세요.' + error })
				setShowDetail(false)
			}
		}
	}

	const handleDelete = async () => {
		const result = window.confirm('해당 캠페인을 삭제하겠습니까?')
		if (result) {
			try {
				const res = await deleteSubject(detailData?.seq)
				if (res.status.code === 200) {
					alertMessage({ title: '알림', type: 'success', message: '삭제가 완료되었습니다.' })
					setShowDetail(false)
					setTimeout(() => {
						window.location.reload()
					}, 100)
				} else {
					alertMessage({ title: '경고', type: 'danger', message: '에러가 발생하였습니다.\n관리자에게 문의해주세요.' + res })
					setShowDetail(false)
				}
			} catch (error) {
				console.error(error)
				alertMessage({ title: '경고', type: 'danger', message: '에러가 발생하였습니다.\n관리자에게 문의해주세요.' + error.message })
				setShowDetail(false)
			}
		}
	}

	const handleCompanyChange = async (value, changeValue, onChange) => {
		onChange({ ...value, advertiser: changeValue })
		const params = {
			search: changeValue
		}
		debounce(async () => {
			const res = await getManagerList(params)
			setManagerInfo(res.data)
		}, 100)
		setShowManagerList(true)
	}

	const clickManager = async (value, manager, onChange) => {
		onChange({ ...value, advertiser: manager.advertiser })
		setManagerInfo(manager)
		setShowManagerList(false)

		setTimeout(async () => {
			loadCampaignInfo(manager.seq)
		}, 100)
	}

	const loadCampaignInfo = async (managerSeq) => {
		const res = await getCampaignList(managerSeq)
		setCampaignInfo(res.data)
	}

	const clickCampaign = async (value, changeValue, onChange) => {
		onChange({ ...value, seq: changeValue })

		const res = await getCostList(changeValue)
		setCostInfo(res.data)
	}

	const loadCostInfo = async (campaignSeq) => {
		const res = await getCostList(campaignSeq)
		setCostInfo(res.data)
	}

	const clickCost = async (value, changeValue, onChange) => {
		onChange({ ...value, seq: changeValue })
		setDetailCost(costInfo.filter((data) => data.seq === changeValue)[0])
	}

	const sendImage = async (formData) => {
		try {
			const data = await adsImageUpload(formData)
			return data
		} catch (error) {
			console.error('이미지 업로드 중 오류 발생:', error)
		}
	}

	const handleImageChange = async (e, onChange) => {
		const file = e.target.files[0]
		const options = {
			maxSizeMB: 2, // 최대 파일 크기
			maxWidthOrHeight: 1920, // 최대 너비 또는 높이
			useWebWorker: true
		}

		try {
			if (file) {
				setFileName(file.name)

				// 이미지 압축
				const compressedFile = await imageCompression(file, options)

				// 압축된 이미지를 FormData에 추가
				const formData = new FormData()
				formData.append('file', compressedFile, file.name)

				const { data } = await sendImage(formData)
				onChange(data)
			}
		} catch (error) {
			console.error('이미지 압축 중 오류 발생:', error)
		}
	}

	const renderPage = () => (
		<div className="w-[700px]">
			<div className="flex flex-col">
				<div className="flex justify-start items-center mb-3">
					<span className="text-[15px] text-right w-24 mr-3">소재 제목</span>
					<Controller
						name="title"
						control={control}
						render={({ field: { onChange, value } }) => (
							<TextField
								placeholder="소재 제목 입력"
								type="text"
								value={value}
								onChange={onChange}
								style={{ width: '73%', minWidth: '160px' }}
								inputProps={{
									style: { fontSize: '15px' }
								}}
							/>
						)}
					/>
				</div>
				<div className="flex justify-start items-center mb-3">
					<div className="flex items-center ">
						<span className="text-[15px] text-right w-24 mr-3 relative">광고주</span>
						<Controller
							name="manager"
							control={control}
							render={({ field: { onChange, value } }) => (
								<>
									<TextField
										placeholder=""
										type="text"
										value={value?.advertiser || ''}
										onChange={(e) => handleCompanyChange(value, e.target.value, onChange)}
										style={{ width: '200px', minWidth: '200px' }}
										inputProps={{
											style: { fontSize: '15px' }
										}}
									/>
									{showManagerList && (
										<div className="bg-white max-h-[35%] min-w-[200px] shadow-lg border border-black rounded-md z-10 absolute top-[21.5%] left-[124px]  overflow-scroll">
											{managerInfo.length > 0 ? (
												<>
													{managerInfo.map((manager) => {
														return (
															<div
																key={manager?.seq}
																className="p-2 hover:bg-gray-100 hover:cursor-pointer"
																onClick={() => clickManager(value, manager, onChange)}
															>
																{manager.advertiser} ({manager.name})
															</div>
														)
													})}
												</>
											) : (
												<div className="p-2 hover:bg-gray-100 hover:cursor-pointer">검색결과 없음</div>
											)}
										</div>
									)}
								</>
							)}
						/>
					</div>
					<div className="flex w-1/2 items-center">
						<span className="text-[15px] text-right w-24 mr-3">광고위치</span>
						<Controller
							name="inventory"
							defaultValue={'0'}
							control={control}
							render={({ field: { onChange, value } }) => (
								<Select
									style={{ width: '200px', minWidth: '200px' }}
									value={
										value === 'place'
											? adType === 'popup'
												? 'popup'
												: adType === 'notice'
												? 'notice'
												: 'place'
											: value
									}
									onChange={(e) => {
										const value = e.target.value
										onChange(value)
										if (value == 'notice') {
											setAdType('notice')
											setAdLocation('place')
											setShowText(true)
										} else {
											if (value == 'popup') {
												setAdType('popup')
												setShowText(false)
												setAdLocation('place')
											} else {
												setAdLocation(value)
												if (value == 'place') {
													setAdType('feed')
													setShowText(true)
													setAdLocation(value)
												} else if (value == 'myPlace') {
													setAdType('banner')
													setShowText(false)
													setAdLocation(value)
												} else if (value == 'myPage') {
													setAdType('banner')
													setShowText(false)
													setAdLocation(value)
												} else if (value == 'moim') {
													setAdType('feed')
													setShowText(true)
													setAdLocation(value)
												}
											}
											
										}
										setContents('')
									}}
								>
									<MenuItem value={'0'}>선택하세요</MenuItem>
									<MenuItem value={'notice'}>전면공지</MenuItem>
									<MenuItem value={'popup'}>전면팝업</MenuItem>
									<MenuItem value={'place'}>공간</MenuItem>
									<MenuItem value={'myPlace'}>마이페이지</MenuItem>
									<MenuItem value={'myPage'}>내 공간</MenuItem>
									<MenuItem value={'moim'}>모임</MenuItem>
								</Select>
							)}
						/>
					</div>
				</div>
				<div className="flex justify-start items-center mb-3">
					<div className="flex items-center">
						<span className="text-[15px] text-right w-24 mr-3">캠페인명</span>
						<Controller
							name="campaign"
							control={control}
							render={({ field: { onChange, value } }) => (
								<Select
									style={{ width: '200px', minWidth: '200px' }}
									value={value?.seq || '0'}
									onChange={(e) => clickCampaign(value, e.target.value, onChange)}
								>
									<MenuItem value={'0'}>선택하세요</MenuItem>
									{campaignInfo.length > 0 &&
										campaignInfo.map((data) => <MenuItem key={data?.seq} value={data?.seq}>{data.title}</MenuItem>)}
								</Select>
							)}
						/>
					</div>
					<div className="flex w-1/2 items-center">
						<span className="text-[15px] text-right w-24 mr-3">과금방식</span>
						<Controller
							name="cost"
							control={control}
							defaultValue={'0'}
							render={({ field: { onChange, value } }) => (
								<Select
									style={{ width: '200px', minWidth: '200px', marginRight: '4px' }}
									value={value?.seq || '0'}
									onChange={(e) => {
										clickCost(value, e.target.value, onChange)
									}}
								>
									<MenuItem value={'0'}>선택하세요</MenuItem>
									{costInfo.length > 0 &&
										costInfo.map((data) => {
											return <MenuItem key={data?.seq} value={data?.seq}>{data.nickName}</MenuItem>
										})}
								</Select>
							)}
						/>
					</div>
				</div>
				<div className="flex justify-start items-center mb-1">
					<div className="flex items-center">
						<span className="text-[15px] text-right w-24 mr-3">총 예산</span>
						<Controller
							name="cost"
							control={control}
							render={({ field: { onChange, value } }) => (
								<TextField
									type="text"
									disabled
									value={detailCost?.totalCost || ''}
									onChange={onChange}
									style={{ width: '60%', minWidth: '200px' }}
									inputProps={{
										style: { fontSize: '15px' }
									}}
								/>
							)}
						/>
					</div>
					<div className="flex w-1/2 items-center">
						<span className="text-[15px] text-right w-24 mr-3">라이브 시작일</span>
						<Controller
							name="startDate"
							defaultValue={moment().startOf('hour').toISOString()}
							control={control}
							render={({ field: { onChange, value } }) => (
								<div>
									<DatePicker
										selected={new Date(value)}
										onChange={(date) => onChange(moment(date).toISOString())}
										showTimeSelect
										timeFormat="HH:mm"
										timeIntervals={60}
										timeCaption="시간"
										dateFormat="yyyy-MM-dd a HH:mm"
										placeholderText="날짜와 시간 선택"
										className="text-[15px] min-w-[200px] border border-gray-300 rounded-md p-2"
									/>
								</div>
							)}
						/>
					</div>
				</div>
				<div className="flex justify-start items-center mb-1">
					<div className="flex items-center">
						<span className="text-[15px] text-right w-24 mr-3">일일 예산</span>
						<Controller
							name="cost"
							control={control}
							render={({ field: { onChange, value } }) => (
								<TextField
									type="text"
									disabled
									value={detailCost?.dailyLimit || ''}
									onChange={onChange}
									style={{ width: '60%', minWidth: '200px' }}
									inputProps={{
										style: { fontSize: '15px' }
									}}
								/>
							)}
						/>
					</div>
					<div className="flex w-1/2 items-center">
						<span className="text-[15px] text-right w-24 mr-3">라이브 종료일</span>
						<Controller
							name="endDate"
							control={control}
							defaultValue={moment().startOf('hour').toISOString()}
							render={({ field: { onChange, value } }) => (
								<div>
									<DatePicker
										selected={new Date(value)}
										onChange={(date) => onChange(moment(date).toISOString())}
										showTimeSelect
										timeFormat="HH:mm"
										timeIntervals={60}
										timeCaption="시간"
										dateFormat="yyyy-MM-dd a HH:mm"
										placeholderText="날짜와 시간 선택"
										className="text-[15px] min-w-[200px] border border-gray-300 rounded-md p-2"
									/>
								</div>
							)}
						/>
					</div>
				</div>
				<div className="flex justify-start items-center mb-3">
					<span className="text-[15px] text-right w-24 mr-3">URL</span>
					<Controller
						name="targetUrl"
						control={control}
						render={({ field: { onChange, value } }) => (
							<TextField
								type="text"
								value={value}
								onChange={onChange}
								style={{ width: '73%', minWidth: '160px' }}
								inputProps={{
									style: { fontSize: '15px' }
								}}
							/>
						)}
					/>
				</div>
				<div className="flex justify-start mb-3">
					<span className="mr-3 text-right w-24 text-[15px]">메모</span>
					<Controller
						name="alt"
						control={control}
						render={({ field: { onChange, value } }) => (
							<TextField
								style={{ width: '73%', minWidth: '160px' }}
								placeholder="메모 입력"
								type="text"
								multiline
								rows={3}
								value={value}
								onChange={onChange}
								inputProps={{
									style: {
										width: '100%',
										fontSize: '14px'
									}
								}}
							/>
						)}
					/>
				</div>
				<div className="flex justify-start mb-3">
					<span className="mr-3 text-right w-24 text-[15px]">이미지 업로드</span>
					<button
						className="w-10 box-border p-0 border border-r-0 border-[#000000de] bg-gray-100 text-[13px] hover:brightness-90"
						onClick={() => inputRef?.current.click()}
					>
						파일
						<br />
						선택
					</button>
					<div className="w-[67.4%] h-52 border-[#000000de] border flex flex-col justify-around">
						{watchImageUrl && (
							<>
								<img
									className="w-full h-[85%] object-contain cursor-pointer hover:brightness-90"
									src={watchImageUrl || detailData.imageUrl}
									onClick={() => open(watchImageUrl)}
								/>
								<span className="text-center border-t mb-3 h-4 box-border">{fileName}</span>
							</>
						)}
					</div>

					<Controller
						name="imageUrl"
						control={control}
						render={({ field: { onChange, value, ...field } }) => (
							<Input
								inputRef={inputRef}
								hidden={true}
								type="file"
								style={{ display: 'none' }}
								inputProps={{ accept: 'image/*' }}
								onChange={(e) => handleImageChange(e, onChange)}
								{...field}
							/>
						)}
					/>
				</div>
				{showText && (
					<div className="flex justify-start mb-2">
						<span className="mr-3 text-right w-24 text-[15px]">텍스트 작성</span>
						<Controller
							name="contents"
							control={control}
							render={() => (
								<TextField
									style={{ width: '78%', minWidth: '160px' }}
									type="text"
									multiline
									rows={3}
									value={contents}
									onChange={(e) => setContents(e.target.value)}
									inputProps={{
										style: {
											width: '100%',
											fontSize: '14px'
										},
										maxLength: adType === 'feed' && 15
									}}
								/>
							)}
						/>
					</div>
				)}
			</div>
		</div>
	)
	return (
		<div>
			<CustomDialog onOpen={showDetail} onClose={showDetail} title={`소재 ${dialogType}`} aria-labelledby="event-dialog">
				<DialogContent style={{ padding: 0 }}>{renderPage()}</DialogContent>
				<DialogActions>
					<div style={{ display: 'flex', width: '100%', justifyContent: dialogType == '수정' ? 'space-between' : 'flex-end' }}>
						{dialogType == '수정' && (
							<Button variant="outlined" onClick={handleDelete} color="secondary">
								삭제
							</Button>
						)}
						<div>
							<Button variant="outlined" onClick={() => setShowDetail(false)} color="default" style={{ marginRight: '10px' }}>
								취소
							</Button>
							<Button variant="outlined" onClick={handleSubmit(handleSend)} color="primary" autoFocus>
								등록
							</Button>
						</div>
					</div>
				</DialogActions>
			</CustomDialog>
		</div>
	)
}

export default DetailDialog
